<template>
   <div id="box">
      <h1>匹兹堡睡眠质量指数(PSQI)</h1>
      <p>
         下面一些问题是关于您最近1个月的睡眠情况，请选择或填写最符合您近1个月实际情况的答案。
      </p>

      <h4>1.近一个月，每天上床睡觉是在几点钟
         <el-select v-model="time1"
                    placeholder="请选择">
            <el-option label="19"
                       :value="19"></el-option>
            <el-option label="20"
                       :value="20"></el-option>
            <el-option label="21"
                       :value="21"></el-option>
            <el-option label="22"
                       :value="22"></el-option>
            <el-option label="23"
                       :value="23"></el-option>
            <el-option label="24"
                       :value="24"></el-option>
            <el-option label="1"
                       :value="1"></el-option>
            <el-option label="2"
                       :value="2"></el-option>
            <el-option label="3"
                       :value="3"></el-option>
         </el-select>
      </h4>
      <h4>
         2.近1个月，每晚从上床到入睡通常需要
         <el-input v-model="time2"
                   placeholder="请输入内容"></el-input>分钟？
      </h4>
      <h4>
         3.近1个月，每天早上通常 几点起床。
         <el-select v-model="time3"
                    placeholder="请选择">
            <el-option label="5"
                       :value="5"></el-option>
            <el-option label="6"
                       :value="6"></el-option>
            <el-option label="7"
                       :value="7"></el-option>
            <el-option label="8"
                       :value="8"></el-option>
            <el-option label="9"
                       :value="9"></el-option>
            <el-option label="10"
                       :value="10"></el-option>
            <el-option label="11"
                       :value="11"></el-option>
            <el-option label="12"
                       :value="12"></el-option>
         </el-select>
      </h4>
      <h4>
         4.近1个月，每晚通常实际睡眠为
         <el-input v-model="time4"
                   placeholder="请输入内容"></el-input>
         小时（不等于卧床时间）。
      </h4>
      <h4>5.近1个月，因下列情况影响睡眠而烦恼</h4>
      <label>A：入睡困恼（30分钟内不能入睡）</label><br />
      <el-radio-group v-model="radio">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <label>B:夜间易醒或早醒</label><br />
      <el-radio-group v-model="radio1">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <label>C:夜间去厕所</label><br />
      <el-radio-group v-model="radio2">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <label>D:呼吸不畅</label><br />
      <el-radio-group v-model="radio3">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group>
      <br />
      <label>E：咳嗽或鼾声高</label><br />
      <el-radio-group v-model="radio4">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group>
      <br />
      <label>F：感觉冷</label><br />
      <el-radio-group v-model="radio5">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group>
      <br />
      <label>G：感觉热</label><br />
      <el-radio-group v-model="radio6">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <label>H.做噩梦</label><br />
      <el-radio-group v-model="radio7">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <label>I：疼痛不适</label><br />
      <el-radio-group v-model="radio8">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <label>J：其他影响睡眠的事情（如有，请说明）
         <el-input v-model="text"
                   placeholder="请输入内容"
                   class="input3"></el-input>
      </label><br />
      <el-radio-group v-model="radio9">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group>
      <h4>6.近1个月，总的来说，你认为自己的睡眠质量如何？</h4>
      <el-radio-group v-model="radio6_1">
         <el-radio border
                   size="medium"
                   label="0">很好</el-radio>
         <el-radio border
                   size="medium"
                   label="1">较好</el-radio>
         <el-radio border
                   size="medium"
                   label="2">较差</el-radio>
         <el-radio border
                   size="medium"
                   label="3">很差</el-radio>
      </el-radio-group><br />
      <h4>7.近1个月，您使用催眠药的情况</h4>
      <el-radio-group v-model="radio7_1">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <h4>8.近1个月，你常感觉到疲倦吗？</h4>
      <el-radio-group v-model="radio8_1">
         <el-radio border
                   size="medium"
                   label="0">无</el-radio>
         <el-radio border
                   size="medium"
                   label="1">＜1次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="2">1-2次/周</el-radio>
         <el-radio border
                   size="medium"
                   label="3">≥3次/周</el-radio>
      </el-radio-group><br />
      <h4>9.近1个月，你做事情精力不足吗？</h4>
      <el-radio-group v-model="radio9_1">
         <el-radio border
                   size="medium"
                   label="0">没有</el-radio>
         <el-radio border
                   size="medium"
                   label="1">偶尔有</el-radio>
         <el-radio border
                   size="medium"
                   label="2">有时有</el-radio>
         <el-radio border
                   size="medium"
                   label="3">经常有</el-radio>
      </el-radio-group><br />
      <el-button type="primary"
                 :disabled="isdisabled"
                 @click="ExamSolutionADD">生成综合报告</el-button>
      <el-button type="success"
                 @click="ExamSolutionPSQIADD"
                 :disabled="isdisabled">提交</el-button>
   </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
   mounted () {
      this.get();
   },
   updated () { },
   methods: {
      async ExamSolutionADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=综合评估报告&CID=${this.cid}&CName=${this.name}&Data=${this.M}&Score=${this.total2}&Solution=/&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         // console.log(1111111, data);
         if (data.data.data == true) {
            Message.success("报告已生成，请提交");
            // this.$router.push("/Assessment");
         }
      },
      async ExamSolutionPSQIADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=E12&ETName=睡眠质量量表PSQI&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         // console.log(1111111, data);
         if (data.data.data == true) {
            Message.success("提交成功，返回信息验证界面");
            this.$router.push("/Assessment");
         }
      },
      //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
      get () {
         const patientN = localStorage.getItem("patientN");
         const patientI = localStorage.getItem("patientI");
         const MEQ = localStorage.getItem("MEQ");
         const BAI = localStorage.getItem("BAI");
         const HAMD = localStorage.getItem("HAMD");
         this.name = JSON.parse(patientN);
         this.cid = JSON.parse(patientI);
         this.MEQ_S = JSON.parse(MEQ);
         this.BAI_S = JSON.parse(BAI);
         this.HAMD_S = JSON.parse(HAMD);
      },
   },
   computed: {
      // 存储睡眠质量和睡眠障碍得分的数组
      M () {
         return [
            this.Sleep_quality * 1,
            this.Sleep_disorders * 1,
            this.MEQ_S,
            this.BAI_S,
            this.HAMD_S,
         ];
      },
      x () {
         if (this.time1 >= 19) {
            return Math.abs(this.time1 - 24 - this.time3);
         }
         if (this.time1 < 19) {
            return this.time3 - this.time1;
         }
      },
      y () {
         return (
            this.radio1 * 1 +
            this.radio2 * 1 +
            this.radio3 * 1 +
            this.radio4 * 1 +
            this.radio5 * 1 +
            this.radio6 * 1 +
            this.radio7 * 1 +
            this.radio8 * 1 +
            this.radio9 * 1
         );
      },
      // 睡眠质量得分
      Sleep_quality () {
         return this.radio6_1;
      },
      //   入睡时间得分
      asleep () {
         if (this.time2 <= 15) {
            return (this.score2 = 0);
         }
         if (this.time2 > 15 && this.time2 <= 30) {
            return (this.score2 = 1);
         }
         if (this.time2 > 30 && this.time2 <= 60) {
            return (this.score2 = 2);
         } else {
            return (this.score2 = 3);
         }
      },

      // 催眠药物得分
      sodium_amytal () {
         return this.radio7_1;
      },
      // 睡眠时间得分
      Sleep_time () {
         if (this.time4 > 7) {
            return (this.score4 = 0);
         }
         if (this.time4 > 6 && this.time4 <= 7) {
            return (this.score4 = 1);
         }
         if (this.time4 >= 5 && this.time4 <= 6) {
            return (this.score4 = 2);
         } else {
            return (this.score4 = 3);
         }
      },
      // 睡眠效率得分
      Sleep_efficiency () {
         let x = this.time4 / this.x;
         if (x > 0.85) {
            return (this.score5 = 0);
         }
         if (x >= 0.75 && x <= 0.84) {
            return (this.score5 = 1);
         }
         if (x >= 0.65 && x <= 0.74) {
            return (this.score5 = 2);
         }
         if (x < 0.65) {
            return (this.score5 = 3);
         }
      },
      // 睡眠障碍得分
      Sleep_disorders: {
         get () {
            if (this.y == 0) {
               return (this.score6 = 0);
            }
            if (this.y > 0 && this.y <= 9) {
               return (this.score6 = 1);
            }
            if (this.y > 9 && this.y <= 18) {
               return (this.score6 = 2);
            }
            if (this.y > 18 && this.y <= 27) {
               return (this.score6 = 3);
            }
         },
      },
      //日间功能障碍得分
      Daytime_Dysfunction () {
         if (this.radio8_1 * 1 + this.radio9_1 * 1 == 0) {
            return (this.score7 = 0);
         }
         if (
            this.radio8_1 * 1 + this.radio9_1 * 1 > 0 &&
            this.radio8_1 * 1 + this.radio9_1 * 1 <= 2
         ) {
            return (this.score7 = 1);
         }
         if (
            this.radio8_1 * 1 + this.radio9_1 * 1 >= 3 &&
            this.radio8_1 * 1 + this.radio9_1 * 1 <= 4
         ) {
            return (this.score7 = 2);
         }
         if (
            this.radio8_1 * 1 + this.radio9_1 * 1 >= 5 &&
            this.radio8_1 * 1 + this.radio9_1 * 1 <= 6
         ) {
            return (this.score7 = 3);
         }
      },

      total2 () {
         return (
            this.MEQ_S * 1 +
            this.BAI_S * 1 +
            this.HAMD_S * 1 +
            this.Sleep_disorders * 1 +
            this.Sleep_quality * 1
         );
      },
      total () {
         return (
            this.Sleep_quality * 1 +
            this.asleep * 1 +
            this.sodium_amytal * 1 +
            this.Sleep_time * 1 +
            this.Sleep_efficiency * 1 +
            this.Sleep_disorders * 1 +
            this.Daytime_Dysfunction * 1
         );
      },
      // 分值得出结果
      explain () {
         if (this.total <= 5) {
            return "睡眠质量很好";
         }
         if (this.total >= 6 && this.total <= 10) {
            return "睡眠质量较好";
         }
         if (this.total >= 11 && this.total <= 15) {
            return "睡眠质量一般";
         }
         if (this.total >= 16 && this.total <= 21) {
            return "睡眠质量很差";
         }
      },
      isdisabled () {
         if (
            this.radio == "" ||
            this.radio1 == "" ||
            this.radio2 == "" ||
            this.radio3 == "" ||
            this.radio4 == "" ||
            this.radio5 == "" ||
            this.radio6 == "" ||
            this.radio7 == "" ||
            this.radio8 == "" ||
            this.radio9 == "" ||
            this.radio6_1 == "" ||
            this.radio7_1 == "" ||
            this.radio8_1 == "" ||
            this.radio9_1 == ""
         ) {
            return true;
         }
         if (
            this.radio != "" ||
            this.radio1 != "" ||
            this.radio2 != "" ||
            this.radio3 != "" ||
            this.radio4 != "" ||
            this.radio5 != "" ||
            this.radio6 != "" ||
            this.radio7 != "" ||
            this.radio8 != "" ||
            this.radio9 != "" ||
            this.radio6_1 != "" ||
            this.radio7_1 != "" ||
            this.radio8_1 != "" ||
            this.radio9_1 != ""
         ) {
            return false;
         }
      },
   },
   data () {
      return {
         ETID: "E6",
         MEQ_S: 0,
         BAI_S: 0,
         HAMD_S: 0,
         name: "",
         cid: "",
         score2: 0,
         score4: 0,
         score5: 0,
         score6: 0,
         score7: 0,
         time1: 0,
         time2: 0,
         time3: 0,
         time4: 0,
         radio: "",
         radio1: "",
         radio2: "",
         radio3: "",
         radio4: "",
         radio5: "",
         radio6: "",
         radio7: "",
         radio8: "",
         radio9: "",
         radio6_1: "",
         radio7_1: "",
         radio8_1: "",
         radio9_1: "",

         text: "",
      };
   },
};
</script>

<style lang="scss" scoped>
// @import "./style.scss";
.el-radio {
   margin: 10px;
}
body {
   background: rgb(220, 245, 179);
}
#box {
   width: 100%;
   margin: auto;
   text-align: left;
   margin-left: 40px;
   // background: rgb(206, 245, 175);
}
.label_color {
   color: rgb(202, 44, 207);
}
h1 {
   text-align: center;
}
.option {
   margin-left: 20px;
}
.el-input {
   width: 200px;
}
.input3 {
   width: 160px;
}
h4 {
   color: rgb(140, 102, 245);
}
</style>
